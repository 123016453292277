<template>
  <div>
    <filters-form @submit="filters = $event"/>

    <app-card>
      <template #body>
        <app-table request-url="/api/tasks/table" :fields="fields" :filters="filters" :row-class="rowClass">
          <template #cell(ticket)="data">
            <router-link v-if="data.item.ticket" :to="{name: 'showTicket', params: {id: data.item.ticket.id}}">
              {{ data.item.ticket.title }}
            </router-link>
          </template>
          <template #cell(title)="data">
            <router-link v-if="data.item.ticket" :to="{name: 'showTask', params: {id: data.item.id, ticketId: data.item.ticket.id}}">
              {{ data.value }}
            </router-link>
          </template>
          <template #cell(complex)="data">{{ data.item.ticket ? data.item.ticket.location.block.complex.name : '' }}</template>
          <template #cell(block)="data">{{ data.item.ticket ? data.item.ticket.location.block.name : '' }}</template>
          <template #cell(location)="data">{{ data.item.ticket ? data.item.ticket.location.name : '' }}</template>
          <template #cell(status)="data">
            <span class="label label-pill label-inline" :class="`label-light-${getTaskStatusClass(data.value.name)}`">
              {{ $t(`status.${data.value.name}`) }}
            </span>
          </template>
          <template #cell(responsible)="data">
            <router-link v-if="data.value" :to="{name: 'updateUser', params: {id: data.value.id}}">
              {{ data.value.full_name }}
            </router-link>
          </template>
        </app-table>
      </template>
    </app-card>
  </div>
</template>

<script>
import {getTaskStatusClass} from '@/helpers/statuses-helper';
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';
import FiltersForm from './components/FiltersForm';

export default {
  components: {
    AppCard,
    AppTable,
    FiltersForm,
  },
  data() {
    return {
      filters: null,
      fields: [
        {key: 'ticket', label: this.$t('label.ticket')},
        {key: 'title', label: this.$t('label.title')},
        {key: 'description', label: this.$t('label.description')},
        {key: 'complex', label: this.$t('label.complex')},
        {key: 'block', label: this.$t('label.block')},
        {key: 'location', label: this.$t('label.location')},
        {key: 'status', label: this.$t('label.status'), sortable: true},
        {key: 'responsible', label: this.$t('label.responsible')},
        {key: 'deadline', label: this.$t('label.deadline')},
      ]
    }
  },
  methods: {
    getTaskStatusClass,
    rowClass(item, type) {
      if (!item || type !== 'row') return '';
      if (item.deadline_warning && item.status.name !== 'completed') return 'bg-warning-o-30';
    }
  }
}
</script>