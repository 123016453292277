<template>
  <div class="card mb-7">
    <div v-show="filtersOpened" class="card-body">
        <slot name="body"></slot>
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-secondary px-10 mr-3" @click="filtersOpened = false">
            {{ $t('btn.hide_filters') }}
          </button>
          <button type="button" class="btn btn-primary px-10" ref="filterSubmitBtn" @click="$emit('submit', computedFilters)">
            {{ $t('btn.search') }}
          </button>
        </div>
    </div>
    <div v-show="!filtersOpened" class="card-body p-0">
      <div class="btn btn-secondary btn-block" @click="filtersOpened = true">{{ $t('btn.show_filters') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    filtersOpened: false
  }),
  computed: {
    computedFilters() {
      return Object.fromEntries(Object.entries(this.filters).filter(([_, v]) => {
        return Array.isArray(v) ? v.length : v;
      }));
    }
  }
}
</script>