export function getTicketStatusClass(status) {
  let className;

  switch (status) {
    case 'to_do':
      className = 'danger';
      break;
    case 'in_progress':
      className = 'info';
      break;
    case 'check':
      className = 'warning';
      break;
    case 'completed':
      className = 'success';
      break;
  }

  return className;
}

export function getTicketStatusColor(status) {
  let className;

  switch (status) {
    case 'to_do':
      className = '#F64E60';
      break;
    case 'in_progress':
      className = '#8950FC';
      break;
    case 'check':
      className = '#FFA800';
      break;
    case 'completed':
      className = '#1BC5BD';
      break;
    case 'updated_today':
      className = '#7E8299';
      break;
  }

  return className;
}

export function getTaskStatusClass(status) {
  let className;

  switch (status) {
    case 'to_do':
      className = 'danger';
      break;
    case 'in_progress':
      className = 'info';
      break;
    case 'check':
      className = 'warning';
      break;
    case 'completed':
      className = 'success';
      break;
  }

  return className;
}

export function getTaskStatusColor(status) {
  let className;

  switch (status) {
    case 'to_do':
      className = '#F64E60';
      break;
    case 'in_progress':
      className = '#8950FC';
      break;
    case 'completed':
      className = '#1BC5BD';
      break;
    case 'updated_today':
      className = '#7E8299';
      break;
  }

  return className;
}